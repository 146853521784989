import React from "react";

import ComparisonWrapper, {
  ComparisonRow,
} from "../Comparison/comparison.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const Comparison = () => {
  return (
    <ComparisonWrapper>
      <h2>Know What Makes Clodura.AI the Best Clearbit Alternative</h2>
      <div className="comparison-logos">
        <div>
          <h1>Clodura.AI</h1>
        </div>
        <p>V/S</p>
        <div>
          <h1>Clearbit</h1>
        </div>
      </div>
      <div className="comparisons">
        <ComparisonRow>
          <h4>Price</h4>
          <p className="pink pink-first">$49/Month</p>
          <p className="row-last">$99</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Commitment</h4>
          <p className="pink">Monthly</p>
          <p className="row-last">Monthly</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Credits</h4>
          <p className="pink">Unlimited</p>
          <p className="row-last">275</p>
        </ComparisonRow>
        <ComparisonRow>
          <div className="first-div">
            <h4>Data</h4>
            <h5>Email</h5>
            <h5>Availability</h5>
            <h5>Accuracy</h5>
            <h5>Update Frequency</h5>
          </div>
          <div className="pink">
            <p>&nbsp;</p>
            <p>Corporate</p>
            <p>600M</p>
            <p>95%</p>
            <p>90 Days</p>
          </div>
          <div className="pink-first" style={{ minWidth: "41.5%" }}>
            <p>&nbsp;</p>
            <p>Personal/Corporate</p>
            <p>389M</p>
            <p>94%</p>
            <p>30 Days</p>
          </div>
        </ComparisonRow>
        <ComparisonRow>
          <div className="first-div">
            <h4>Search</h4>
            <h5>Basic Search</h5>
            <h5>Advanced Search</h5>
          </div>
          <div className="pink" style={{ padding: "30px 0" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
          </div>
          <div className="pink-first" style={{ minWidth: "41.5%" }}>
            <p>&nbsp;</p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
            <p>
              <FontAwesomeIcon icon={faCircleXmark} />
            </p>
          </div>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Technographic</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Product Companies</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Healthtech, Fintech etc</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Funding</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>
            Hiring Areas
            <br /> ( Team hiring only)
          </h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Technologies</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Website Keywords</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Tags</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Mobile Numbers</h4>
          <p className="pink">120M</p>
          <p className="row-last">50M</p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Included in the Plan</h4>
          <p className="pink">12M</p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Org Chart</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Integrated Email Campaigns</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Email Analytics</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleCheck} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>LinkedIn outreach</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Email Warmup</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow>
          <h4>Image Personalization</h4>
          <p className="pink">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>
        <ComparisonRow style={{ borderBottom: "none" }}>
          <h4>One Line Suggestion</h4>
          <p className="pink pink-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
          <p className="row-last">
            <FontAwesomeIcon icon={faCircleXmark} />
          </p>
        </ComparisonRow>

        <h6 style={{ display: "flex" }}>
          Disclaimer: This comparison is made based on preliminary research and
          the data that we could find and it may change based on new releases
        </h6>
      </div>
    </ComparisonWrapper>
  );
};

export default Comparison;
